<template>
  <div class="pb-5" data-wizard-type="step-content">
    <div class="money-title-wrapper d-flex align-items-center mb-10">
      <h4 class="font-weight-bold text-dark mb-0">
        All allowed money pages
      </h4>
      <v-btn class="ml-auto" dense small dark color="secondary" @click="addNewPage" :disabled="money.length > 3">
        <v-icon left small>add_circle</v-icon>Add page
      </v-btn>
    </div>
    <div class="navi navi-hover navi-icon-circle navi-spacer-x-0">
      <template v-for="(item, i) in money">
        <div class="money-item-wrapper" v-bind:key="item.id">
          <!--begin::Item-->
          <div class="navi-item" @click="changeActivePageIndex(i)" style="cursor: pointer;">
            <div class="navi-link rounded">
              <div class="symbol symbol-50 mr-3">
                <div class="symbol-label">
                  <v-icon>web</v-icon>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold font-size-lg">
                  {{ pageName(i) }}
                </div>
                <div class="text-muted" style="word-break: break-all;">
                  {{ pageUrl(i) }}
                </div>
              </div>
              <v-btn icon class="mr-5" :disabled="money.length < 2" @click="removePage($event, i)">
                <v-icon>delete</v-icon>
              </v-btn>
            </div>
          </div>
          <div class="row" v-if="money.length > 1">
            <div class="col-lg-11 ml-auto">
              <v-subheader>
                <span>Select the page rotation weight.</span>
                <span class="d-block ml-auto">{{item.rotation + '%'}}</span>
              </v-subheader>
              <v-slider
                :max="100"
                :disabled="money.length < 2"
                v-model="item.rotation"
                :tick-labels="percents"
                step="10"
                ticks="always"
                tick-size="3"
              ></v-slider>
            </div>
          </div>
        </div>
        <!--end::Item-->
      </template>
    </div>

    <!-- <div class="alert" v-if="rotationSum > 100">
      <strong style="color: #e19d39;">Warning!</strong> The amount of rotations is more than 100 percent!.
    </div> -->

    <hr style="margin: 40px 0 40px 0" />

    <h4 class="mb-10 font-weight-bold text-dark">
      Money page [{{currentPageName}}]
    </h4>

    <div class="form-group mb-0">
      <label class="col-lg-6 col-form-label"></label>

      <div class="row gutter-b">
        <div class="col-6">
          <div
            class="btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-10 px-5"
            :class="{ 'active': page.selection == 'new' }"
            @click="changePageSelection('new')"
          >
            <span class="svg-icon svg-icon-3x svg-icon-primary m-0">
              <v-icon>note_add</v-icon>
            </span>
            <span class="d-block font-weight-bold font-size-h6 mt-2">New</span>
          </div>
        </div>
        <div class="col-6">
          <div
            class="btn btn-block btn-light btn-hover-danger text-dark-50 text-center py-10 px-5"
            :class="{ 'active': page.selection == 'inject' }"
            @click="changePageSelection('inject'); page.mode = 'inject'"
          >
            <span class="svg-icon svg-icon-3x svg-icon-primary m-0">
              <v-icon>code</v-icon>
            </span>
            <span class="d-block font-weight-bold font-size-h6 mt-2">Inject</span>
          </div>
        </div>
      </div>
    </div><br>
    <b-alert show variant="light"> <v-icon>info</v-icon>  {{selectionDesc(page.selection)}}</b-alert>

    <hr style="margin: 40px 0 40px 0" />

    <div v-if="page.selection === 'new'">
      <div class="form-group">
        <div class="row">

          <div class="col-lg-7">

            <label>Name</label>
            <input
              type="text"
              class="form-control form-control-solid form-control-lg"
              name="lname"
              placeholder="My money Lander UK 1"
              v-model="page.name"
            />
            <span class="form-text text-muted">Name your page
              </span>
          </div>

          <div class="col-lg-4 offset-lg-1">
            <label>Need to Host Lander?</label>
            <span
              class="switch switch-outline switch-icon switch-success"
            >
              <label>
                <input
                  type="checkbox"
                  checked="checked"
                  name="select"
                  v-model="page.host"
                  @change="queryMoney(activePageIndex)"
                />
                <span></span>
              </label>
              <div class="form-text text-muted">Yes</div>
            </span>

          </div>
        </div><br>
        <div v-if="!page.host"><br>
          <label>Money page URL </label>
          <input
            type="url"
            class="form-control form-control-solid form-control-lg"
            name="lname"
            placeholder="https://mymoneypage.com"
            :value="page.url"
            @input="changeUrl($event)"
          />
          <span class="form-text text-muted"
            >Enter Money Page URL ( Tracker link, lander URL etc...
            )</span
          >

          <br>
          <div class="row">
            <div class="col-lg-4 col-12">
              <label
                >Pass query parameters ?</label
              >
              <span
                class="switch switch-outline switch-icon switch-success"
              >
                <label>
                  <input
                    type="checkbox"
                    checked="checked"
                    name="select"
                    v-model="page.query"
                    @change="queryMoney(activePageIndex)"
                  />
                  <span></span>
                </label>
              </span>
            </div>
            <div class="col-lg-4 col-12">
              <label>Protect Page ?</label>
              <span
                class="switch switch-outline switch-icon switch-success"
              >
                <label>
                  <input
                    type="checkbox"
                    checked="checked"
                    name="select"
                    v-model="page.protect"
                    @change="queryMoney(activePageIndex)"
                  />
                  <span></span>
                </label>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div v-if="page.host">
        <div v-if="page.preview" class="mb-5">
          <label>New Hosted money page URL </label>
          <input
            type="url"
            disabled
            class="form-control form-control-solid form-control-lg"
            v-model="page.preview"
          />
        </div>
        <v-file-input
          v-show="false"
          ref="file-input"
          @change="changeMoneyPageFile(activePageIndex)"
          small-chips accept=".zip" show-size label="New page file(.zip only)"
          prepend-icon=""
          v-model="page.file">
        </v-file-input>
          <div
            class="btn btn-block btn-light btn-hover-success text-dark-50 text-center py-10 px-5"

            @click="uploadFile"
          >
            <span class="svg-icon svg-icon-3x svg-icon-primary m-0">
              <v-icon>upload</v-icon>
            </span>
            <span class="d-block font-weight-bold font-size-h6 mt-2">Upload</span>
          </div>


      </div>
    </div>

    <div v-if="page.selection === 'inject'">
      <h6 class="mb-3" style="font-size: 14px;">
        Inject in head
      </h6>
      <v-textarea
        outlined
        hide-details
        auto-grow
        class="mb-6"
        v-model="page.code_head"
      />
      <h6 class="mb-3" style="font-size: 14px;">
        Inject in body
      </h6>
      <v-textarea
        outlined
        hide-details
        auto-grow
        v-model="page.code_body"
      />
    </div>

    <hr style="margin: 40px 0 40px 0" v-if="page.selection != 'inject'" />

    <div class="form-group" v-if="page.selection != 'inject'">
      <label class="col-lg-6 col-form-label font-weight-bold" style="font-size: 14px;"
        >How to load your Money Page
      </label>

      <div class="row gutter-b mb-3">
        <div class="col-4">
          <div
            class="btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-10 px-5"
            :class="{ 'active': page.mode == 'iframe' }"
            @click="page.mode = 'iframe'"
          >
            <span class="svg-icon svg-icon-3x svg-icon-primary m-0">
              <v-icon>preview</v-icon>
            </span>
            <span class="d-block font-weight-bold font-size-h6 mt-2">IFRAME</span>
          </div>
        </div>
        <div class="col-4">
          <div
            class="btn btn-block btn-light btn-hover-success text-dark-50 text-center py-10 px-5"
            :class="{ 'active': page.mode == 'reverse proxy' }"
            @click="page.mode = 'reverse proxy'"
          >
            <span class="svg-icon svg-icon-3x svg-icon-primary m-0">
              <v-icon>loop</v-icon>
            </span>
            <span class="d-block font-weight-bold font-size-h6 mt-2">REVERSE PROXY</span>
          </div>
        </div>
        <div class="col-4">
          <div
            class="btn btn-block btn-light btn-hover-danger text-dark-50 text-center py-10 px-5"
            :class="{ 'active': page.mode == 'redirect' }"
            @click="page.mode = 'redirect'"
          >
            <span class="svg-icon svg-icon-3x svg-icon-primary m-0">
              <v-icon>open_in_new</v-icon>
            </span>
            <span class="d-block font-weight-bold font-size-h6 mt-2">REDIRECT</span>
          </div>
        </div>
      </div>

      <b-alert show variant="light"> <v-icon>info</v-icon>  {{modeDesc(page.mode)}}</b-alert>
    </div>

    <hr style="margin: 40px 0 40px 0" v-if="page.mode === 'reverse proxy'" />

    <div class="form-group" v-if="page.mode === 'reverse proxy'">
      <label class="col-form-label col-12"
        >Select this option if reverse proxy mode break your page layout.
      </label>
      <div class="col-lg-12">
        <span
          class="switch switch-sm switch-outline whitelist-switch"
        >
          <label class="d-flex align-items-center">
            <input
              type="checkbox"
              name="select"
              v-model="page.nojs"
            /><span></span>
            <div class="form-text ml-2 mt-0">Disabled JS</div>
          </label>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    money: Array,
    changeMoneyPageFile: Function,
    queryMoney: Function,
    campaigns: Array
  },
  data: () => ({
    percents: ['0%', '10%', '20%', '30%', '40%', '50%', '60%', '70%', '80%', '90%', '100%'],
    activePageIndex: 0
  }),
  computed: {
    page() {
      return this.money[this.activePageIndex] || this.money[0]
    },
    currentPageName() {
      return this.pageName(this.activePageIndex);
    },
    rotationSum() {
      let sum = 0;

      this.money.forEach(page => {
        sum += (page.rotation || 0)
      });

      return sum;
    }
  },
  methods: {
    uploadFile() {
      if (this.$refs['file-input'] && this.$refs['file-input'].$refs.input) {
        this.$refs['file-input'].$refs.input.click()
      }
    },
    changePageSelection(selection) {
      this.$set(this.money, this.activePageIndex, {
        ...this.money[this.activePageIndex],
        selection: selection
      })
    },
    addNewPage() {
      this.money.push({
        id: Math.random().toString(36).substr(2, 9),
        name: '',
        url: '',
        mode: 'iframe',
        new: 'true',
        query: false,
        protect: false,
        host: false,
        file: null,
        selection: 'new',
        nojs: false,
        rotation: 0,
        code_head: '<script><\/script>',
        code_body: '<script><\/script>'
      });

      this.activePageIndex = this.money.length - 1;
    },
    pageName(index) {
      return (this.money[index] && this.money[index].name) || `Page #${index + 1}`
    },
    pageUrl(index) {
      return this.money[index].url || `URL for this page has not yet been specified`
    },
    removePage(event, index) {
      event.stopImmediatePropagation();

      this.activePageIndex = 0;

      this.$nextTick(() => {
        this.money.splice(index, 1);
      })
    },
    changeActivePageIndex(index) {
      this.activePageIndex = index;
    },
    onModeChange(event) {
      this.$nextTick(() => {
        this.$set(this.money, this.activePageIndex, {
          ...this.money[this.activePageIndex],
          mode: event.target.value
        })
      })
    },
    selectionDesc(selection) {
      const assoc = {
        "new": "Add your custom money page",
        "collection": "Choose a lander from our library",
        "inject": "Stay on same page and inject JS code to modify its content",
        "copy": "Copy page from an existing campaign",
        "direct": "Go straight to the offer"
      }

      return assoc[selection] || '';
    },
    modeDesc(mode) {
      const assoc = {
        "iframe": "Load your money page inside an IFRAME. RECOMMENDED",
        "reverse proxy": "Insert money page content inside the page ( ~ CURL ). Warning: Might break page layout",
        "redirect": "Redirect to your money page ( the URL will change ). NOT RECOMMENDED"
      }

      return assoc[mode] || '';
    },
    changeUrl(event) {
      if (event.target.value === 'https:/') {
        event.target.value = 'https://';
      } else if (!event.target.value.startsWith('https://')) {
        event.target.value = `https://${event.target.value}`;
      }

      this.$set(this.page, 'url', event.target.value);
    }
  }
}
</script>
