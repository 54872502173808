<template>
  <!--begin: Wizard Nav -->
  <div class="wizard-nav border-right py-8 px-8 py-lg-20 px-lg-10">
    <div class="wizard-steps">
      <div
        class="wizard-step"
        data-wizard-type="step"
        data-wizard-state="current"
        @click="submit($event, true)"
      >
        <div class="wizard-wrapper">
          <div class="wizard-icon">
            <span class="svg-icon svg-icon-2x">
              <v-icon>campaign</v-icon>
            </span>
          </div>
          <div class="wizard-label">
            <h3 class="wizard-title">
              Campaign Settings
            </h3>
            <div class="wizard-desc">
              Setup Your Campaign
            </div>
          </div>
        </div>
      </div>

      <div class="wizard-step" data-wizard-type="step" @click="submit($event, true)">
        <div class="wizard-wrapper">
          <div class="wizard-icon">
            <span class="svg-icon svg-icon-2x">
              <v-icon>verified_user</v-icon>
            </span>
          </div>
          <div class="wizard-label">
            <h3 class="wizard-title">
              Filter Settings
            </h3>
            <div class="wizard-desc">
              How to filter traffic
            </div>
          </div>
        </div>
      </div>



      <div class="wizard-step" href="#" data-wizard-type="step" @click="submit($event, true)">
        <div class="wizard-wrapper">
          <div class="wizard-icon">
            <span class="svg-icon svg-icon-2x">
              <v-icon>request_page</v-icon>
            </span>
          </div>
          <div class="wizard-label">
            <h3 class="wizard-title">
              Money Page
            </h3>
            <div class="wizard-desc">
              Where to send real visitors
            </div>
          </div>
        </div>
      </div>

      <div class="wizard-step" href="#" data-wizard-type="step" @click="submit($event, true)">
        <div class="wizard-wrapper">
          <div class="wizard-icon">
            <span class="svg-icon svg-icon-2x">
              <v-icon>article</v-icon>
            </span>
          </div>
          <div class="wizard-label">
            <h3 class="wizard-title">
              Safe page
            </h3>
            <div class="wizard-desc">
              Where to send bot
            </div>
          </div>
        </div>
      </div>

      <div class="wizard-step" href="#" data-wizard-type="step" @click="submit($event, true)">
        <div class="wizard-wrapper">
          <div class="wizard-icon">
            <span class="svg-icon svg-icon-2x">
              <v-icon>downloading</v-icon>
            </span>
          </div>
          <div class="wizard-label">
            <h3 class="wizard-title">
              Installation
            </h3>
            <div class="wizard-desc">
              Choose installation
            </div>
          </div>
        </div>
      </div>

      <div class="wizard-step" data-wizard-type="step" @click="submit($event, true)">
        <div class="wizard-wrapper">
          <div class="wizard-icon">
            <span class="svg-icon svg-icon-2x">
              <inline-svg src="/media/svg/icons/General/Like.svg" />
            </span>
          </div>
          <div class="wizard-label">
            <h3 class="wizard-title">
              Completed!
            </h3>
            <div class="wizard-desc">
              Review and Save
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <hr style="margin: 20px 0 20px 0" /> -->

    <div class="state mt-10" v-if="state">
      <div class="loading" v-if="state === 'loading'">
        <v-progress-circular
          indeterminate
          color="primary"
          class="mr-4"
          size="23"
        ></v-progress-circular>
        <span>Saving ...</span>
      </div>
      <div class="saved" v-if="state === 'saved'">
        <v-icon class="mr-4">task_alt</v-icon>
        <span>Saved</span>
      </div>
     
    </div>
  </div>
  <!--end: Wizard Nav -->
</template>

<script>
export default {
  props: {
    submit: Function,
    state: String
  }
}
</script>

<style>
.state {
  padding: 0 20px;
}

.saved span, .saved i {
  color: #0BB7AF !important;
}
</style>