<template>
  <div class="pb-5" data-wizard-type="step-content">
    <h4 class="mb-10 font-weight-bold text-dark">
      Safe page
    </h4>

    <div class="form-group">
      <label class="col-lg-6 col-form-label"
        >What to do with bot
      </label>
      <div class="row gutter-b mb-3">
        <div class="col-6">
          <div
            class="btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-10 px-5"
            :class="{ 'active': safe.mode == 'stay' }"
            @click="changeSafeMode('stay')"
          >
            <span class="svg-icon svg-icon-3x svg-icon-primary m-0">
              <v-icon>tour</v-icon>
            </span>
            <span class="d-block font-weight-bold font-size-h6 mt-2">Stay</span>
          </div>
        </div>
        <div class="col-6">
          <div
            class="btn btn-block btn-light btn-hover-success text-dark-50 text-center py-10 px-5"
            :class="{ 'active': safe.mode == 'reverse proxy' }"
            @click="changeSafeMode('reverse proxy')"
          >
            <span class="svg-icon svg-icon-3x svg-icon-primary m-0">
              <v-icon>settings_ethernet</v-icon>
            </span>
            <span class="d-block font-weight-bold font-size-h6 mt-2">Another page</span>
          </div>
        </div>
      </div>
      <b-alert show variant="light"> <v-icon>info</v-icon>  {{modeDesc(safe.mode)}}</b-alert>
    </div>

    <div v-if="safe.new == 'true' && safe.mode != 'stay' ">
      <hr style="margin: 40px 0 40px 0" />
      <div class="form-group">
        <label>Name</label>
        <input
          type="text"
          class="form-control form-control-solid form-control-lg"
          name="lname"
          placeholder="Safe page for UK campaign"
          v-model="safe.name"
        />
        <span class="form-text text-muted">Name your page</span>
        <br>
        <label>Safe page URL</label>
        <input
          type="text"
          class="form-control form-control-solid form-control-lg"
          name="lname"
          placeholder="https://mysafepage.com"
          v-model="safe.url"
        />

        <span class="form-text text-muted">Enter Safe Page URL (the page you want to show to bot)</span>
      </div>

      <label class="col-12 col-form-label"
        >Pass query parameters?</label
      >

      <div class="col-3">
        <span
          class="switch switch-outline switch-icon switch-success"
        >
          <label>
            <input
              type="checkbox"
              checked="checked"
              name="select"
              v-model="safe.query"
              @change="querySafe"
            />
            <span></span>
          </label>
        </span>
      </div>

      <div class="form-group"  >
        <hr style="margin: 40px 0 40px 0" />
        <label class="col-lg-6 col-form-label"
          >How to load your Safe Page
        </label>

        <div class="row gutter-b mb-3">
          <div class="col-4">
            <div
              class="btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-10 px-5"
              :class="{ 'active': safe.anotherMode == 'iframe' }"
              @click="safe.anotherMode  = 'iframe'"
            >
              <span class="svg-icon svg-icon-3x svg-icon-primary m-0">
                <v-icon>preview</v-icon>
              </span>
              <span class="d-block font-weight-bold font-size-h6 mt-2">IFRAME</span>
            </div>
          </div>
          <div class="col-4">
            <div
              class="btn btn-block btn-light btn-hover-success text-dark-50 text-center py-10 px-5"
              :class="{ 'active': safe.anotherMode == 'reverse proxy' }"
              @click="safe.anotherMode = 'reverse proxy'"
            >
              <span class="svg-icon svg-icon-3x svg-icon-primary m-0">
                <v-icon>loop</v-icon>
              </span>
              <span class="d-block font-weight-bold font-size-h6 mt-2">REVERSE PROXY</span>
            </div>
          </div>
          <div class="col-4">
            <div
              class="btn btn-block btn-light btn-hover-danger text-dark-50 text-center py-10 px-5"
              :class="{ 'active': safe.anotherMode == 'redirect' }"
              @click="safe.anotherMode = 'redirect'"
            >
              <span class="svg-icon svg-icon-3x svg-icon-primary m-0">
                <v-icon>open_in_new</v-icon>
              </span>
              <span class="d-block font-weight-bold font-size-h6 mt-2">REDIRECT</span>
            </div>
          </div>
        </div>

        <b-alert show variant="light"> <v-icon>info</v-icon>  {{anotherModeDesc(safe.anotherMode)}}</b-alert>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    safe: Object,
    querySafe: Function
  },
  methods: {
    changeSafeMode(mode) {
      this.safe.mode = mode;
    },
    modeDesc(mode) {
      const assoc = {
        "stay": "Visitor will stay on current page. That's usually the best way. RECOMMENDED",
        "reverse proxy": "Use this if you don't have a safe page and you want to load another page content"
      }

      return assoc[mode] || '';
    },
    anotherModeDesc(mode) {
      const assoc = {
        "iframe": "Load page content inside an IFRAME. NOT RECOMMENDED",
        "reverse proxy": "Curl external page and insert content. Warning: Layout might break",
        "redirect": "Redirect to your safe page. ( The URL will change )"
      }

      return assoc[mode] || '';
    }
  }
}
</script>
