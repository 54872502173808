<template>
  <div
    class="pb-5"
    data-wizard-type="step-content"
    data-wizard-state="current"
  >
    <h4 class="mb-10 font-weight-bold text-dark">
      Campaign overview
    </h4>
    <div class="form-group">
      <label>Campaign Name</label>
      <input
        type="text"
        class="form-control form-control-solid form-control-lg"
        name="fname"
        placeholder="UK NativeAds Crypto"
        value=""
        v-model="campaign.name"
      />
      <span class="form-text text-muted"
        >Give your campaign a name </span
      >
    </div>
    <div class="form-group">
      <label>Campaign URL</label>
      <input
        type="text"
        class="form-control form-control-solid form-control-lg"
        name="lname"
        v-model="campaign.url"
        placeholder="https://mycampaign.com"
        value=""

      />
      <span class="form-text text-muted"
        >Enter campaign URL ( the link you want to use in the traffic source )</span
      >
    </div>
    <div class="form-group">
      <label>Status</label>
      <v-select
        class="campaign-status"
        v-model="campaign.status"
        :items="status"
        dense
        item-value="value"
        :menu-props="{ top: false, offsetY: true }"
        hide-details
        item-text="text"
        outlined
        attach
      >
        <!-- <template v-slot:item="{ item }">
          <v-chip
            small
            :color="item.color || 'green'"
          >
            {{item.text}}
          </v-chip>
        </template>
        <template v-slot:selection="{ item }">
          <v-chip
            small
            :color="item.color || 'green'"
          >
            {{item.text}}
          </v-chip>
        </template> -->
      </v-select>
      <span class="form-text text-muted"
        >Select Campaign Status</span
      ><br><br>

      <div v-if="campaign.status == 'allow_all'">
        <b-alert show variant="warning"> <v-icon>warning</v-icon>  All traffic will go through. Everybody will see money page. Don't use on live traffic</b-alert>
      </div>

      <div v-if="campaign.status == 'block_all'">
        <b-alert show variant="light"> <v-icon>info</v-icon>  All traffic will be cloaked. Everybody will see safe page. Choose this when your campaign is under review</b-alert>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  props: {
    campaign: Object
  },
  data: () => ({
    status: [
      { text: 'Allow all', value: 'allow_all', color: '#ffeab6' },
      { text: 'Active', value: 'active', color: '#d1f7c4' },
      { text: 'Block All', value: 'block_all', color: '#ffdce5' }
    ]
  })
}
</script>

<style scoped>
::v-deep .campaign-status fieldset {
  border: 0;
}

::v-deep .campaign-status .v-input__control {
  background-color: #F3F6F9;
  border-color: #F3F6F9;
  color: #3F4254;
}

::v-deep .campaign-status .v-select__selection {
  font-size: 13px;
}
</style>
