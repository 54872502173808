<template>
  <div class="pb-5" data-wizard-type="step-content">
    <h4 class="mb-10 font-weight-bold text-dark">
      Installation
    </h4>

    <v-snackbar
      v-model="snackbar.value"
    >
      {{ snackbar.message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <div class="card-body">
      <div class="form-group">
        <label class="col-lg-6 col-form-label font-weight-bold">Cloaking installation</label>

        <div class="row gutter-b">
          <div class="col-4">
            <div
              class="btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-10 px-5"
              :class="{ 'active': campaign.mode == 'js' }"
              @click="changeCampaignMode('js')"
            >
              <span class="svg-icon svg-icon-3x svg-icon-primary m-0">
                <v-icon class="pr-0">code</v-icon>
              </span>
              <span class="d-block font-weight-bold font-size-h6 mt-2">JS</span>
            </div>
          </div>
          <div class="col-4">
            <div
              class="btn btn-block btn-light btn-hover-success text-dark-50 text-center py-10 px-5"
              :class="{ 'active': campaign.mode == 'dns' }"
              @click="changeCampaignMode('dns')"
            >
              <span class="svg-icon svg-icon-3x svg-icon-primary m-0">
                <v-icon class="pr-0">dns</v-icon>
              </span>
              <span class="d-block font-weight-bold font-size-h6 mt-2">DNS</span>
            </div>
          </div>
          <div class="col-4">
            <div
              class="btn btn-block btn-light btn-hover-success text-dark-50 text-center py-10 px-5"
              :class="{ 'active': campaign.mode == 'api', 'disabled': userdata.plan !== 'agency' }"
              @click="userdata.plan === 'agency' ? changeCampaignMode('api') : showSnackbar('Only allow for Agency plan')"
            >
              <span class="svg-icon svg-icon-3x svg-icon-primary m-0">
                <v-icon class="pr-0">api</v-icon>
              </span>
              <span class="d-block font-weight-bold font-size-h6 mt-2">API</span>
            </div>
          </div>
        </div>
      </div>
      <b-alert v-if="campaign.mode" show variant="light"> <v-icon>info</v-icon>  {{modeDesc}}</b-alert>

      <hr style="margin: 40px 0 40px 0" v-if="campaign.mode" />
      <div class="row code-wrapper" style="margin: 0 !important;" v-if="campaign.mode" >
        <code-highlight ref="codehighlight" class="col-12 codehighlight" style="max-width:800px;" language="javascript" :key="campaign.mode">
          {{installation[campaign.mode]}}
        </code-highlight>
        <button type="button" class="copy-btn"
          v-clipboard:copy="installation[campaign.mode]"
          v-clipboard:success="onSuccessCopy"
        >Copy</button>
      </div>
    </div>
  </div>
</template>

<script>
import CodeHighlight from "vue-code-highlight/src/CodeHighlight.vue";
import "vue-code-highlight/themes/duotone-sea.css";
import "vue-code-highlight/themes/window.css";

import { mapGetters } from "vuex";

export default {
  props: {
    campaign: Object,
    installation: Object,
    onSuccessCopy: Function
  },
  components: {
    CodeHighlight
  },
  data: () => ({
    snackbar: {
      value: false,
      message: ''
    }
  }),
  computed: {
    modeDesc() {
      if (!this.campaign.mode) return;

      if (this.campaign.mode == 'js') {
        return 'Copy paste the js code into the head tag of your page.'
      } else if (this.campaign.mode == 'dns') {
        return 'Install cloaker on your domain DNS with Cloudflare.';
      } else {
        return 'Use a GET request to the cloaker API. For Custom integration '
      }
    },
    ...mapGetters([
      "userdata"
    ]),
  },
  methods: {
    changeCampaignMode(mode) {
      this.campaign.mode = mode;
    },
    showSnackbar(message) {
      this.snackbar = {
        value: true,
        message
      }
    }
  }
}
</script>
