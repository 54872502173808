<template>
  <div class="pb-5" data-wizard-type="step-content">
    <h4 class="mb-10 font-weight-bold text-dark">
      Filter - {{tabName}} Settings
    </h4>

    <div class="row gutter-b">
      <div class="col-6">
        <div
          class="btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-10 px-5"
          :class="{ 'active': activeTab == 'basic' }"
          @click="changeActiveTab('basic')"
        >
          <span class="svg-icon svg-icon-3x svg-icon-primary m-0">
            <v-icon>settings_ethernet</v-icon>
          </span>
          <span class="d-block font-weight-bold font-size-h6 mt-2">Basic</span>
        </div>
      </div>
      <div class="col-6">
        <div
          class="btn btn-block btn-light btn-hover-success text-dark-50 text-center py-10 px-5"
          :class="{ 'active': activeTab == 'day_parting' }"
          @click="changeActiveTab('day_parting')"
        >
          <span class="svg-icon svg-icon-3x svg-icon-primary m-0">
            <v-icon>event</v-icon>
          </span>
          <span class="d-block font-weight-bold font-size-h6 mt-2">Day parting</span>
        </div>
      </div>
    </div>
    <div class="row gutter-b">
      <div class="col-4">
        <div
          class="btn btn-block btn-light btn-hover-info text-dark-50 text-center py-10 px-5"
          :class="{ 'active': activeTab == 'location' }"
          @click="changeActiveTab('location')"
        >
          <span class="svg-icon svg-icon-3x svg-icon-primary m-0">
            <v-icon>explore</v-icon>
          </span>
          <span class="d-block font-weight-bold font-size-h6 mt-2">Location</span>
        </div>
      </div>
      <div class="col-4">
        <div
          class="btn btn-block btn-light btn-hover-warning text-dark-50 text-center py-10 px-5"
          :class="{ 'active': activeTab == 'device' }"
          @click="changeActiveTab('device')"
        >
          <span class="svg-icon svg-icon-3x svg-icon-primary m-0">
            <v-icon>devices</v-icon>
          </span>
          <span class="d-block font-weight-bold font-size-h6 mt-2">Device</span>
        </div>
      </div>
      <div class="col-4">
        <div
          class="btn btn-block btn-light btn-hover-danger text-dark-50 text-center py-10 px-5"
          :class="{ 'active': activeTab == 'connection' }"
          @click="changeActiveTab('connection')"
        >
          <span class="svg-icon svg-icon-3x svg-icon-primary m-0">
            <v-icon>account_tree</v-icon>
          </span>
          <span class="d-block font-weight-bold font-size-h6 mt-2">Connection</span>
        </div>
      </div>
    </div>

    <hr style="margin: 50px 0 20px 0" />

    <div class="col-lg-12" v-if="activeTab == 'basic'">


    <!--  <label><span class="font-weight-bold font-size-h5">Filter strength</span> <br>Minimum score for a visitor to be allowed :   <v-chip class="ma-2">{{filter.score}}/100</v-chip></label>-->
      <v-subheader
        v-text="'Select the filter strength. '"
      ></v-subheader>

      <div class="row d-flex align-items-center" style="flex-wrap: nowrap; margin: 10px 0 0 0">

        <round-slider
          v-model="filter.score"
          pathColor="#1BC5BD"
          rangeColor="#1BC5BD"
          class="mr-5"
          :max="49"
        />
        <div style="margin: -50px 0 0 100px" v-html="scoreInfo">
        </div>
      </div>
      <br>
      <b-alert show variant="light"> <v-icon>info</v-icon> Base on your selected strength, we automatically enforce data checks :<br> - Select a low score to optimize for delivery <br> - Select a high score to optimize for protection ( in exchange of higher bleed rate ).
        <br><br> Strength below 50 is server side only <br> Strength above 50 is both server and client side</b-alert>

        <b-alert v-if="filter.score >=50" show variant="info"> <v-icon>warning</v-icon> Fingerprinting code injected client side. <a style="color:white; text-decoration:underline" target="_blank" href="https://docs.cloakone.com/">See docs</a></b-alert>


      <hr style="margin: 50px 0 50px 0" />

      <label><span class="font-weight-bold font-size-h5">Visits</span> <br>Maximum visits allowed <v-chip class="ma-2">{{visitsCount}}</v-chip></label>
      <v-subheader
        v-text="'Select the maximum number of visit allowed. '"
      ></v-subheader>

      <div class="row">
        <div class="col-lg-11">
          <v-slider
            v-model="filter.visit_allowed"
            :tick-labels="visits"
            :max="11"
            :min="1"
            step="1"
            ticks="always"
            tick-size="4"
          ></v-slider>
        </div>
      </div>

      <hr style="margin: 50px 0 50px 0" />

      <div class="row">
        <div class="col-lg-4">
          <label class="font-weight-bold font-size-h5">Country</label>
          <v-subheader
            v-text="'Select countries'"
          ></v-subheader>
        </div>

        <div class="col-lg-8">
          <!-- <b-form-checkbox class="whitelist-switch" v-model="filter.countryWhitelist" switch>
            Whitelist
          </b-form-checkbox> -->
          <span
            class="switch switch-sm switch-outline whitelist-switch"
          >
            <div class="form-text mr-2 mt-0">Blacklist</div>
            <label><input
                type="checkbox"
                checked="checked"
                name="select"
                v-model="filter.countryWhitelist"
            /><span></span></label>
            <div class="form-text ml-1 mt-0">Whitelist</div>
          </span>
          <v-autocomplete
            v-model="filter.country"
            :items="countries"
            persistent-hint
            chips
            deletable-chips
            hide-details
            attach
            placeholder="Type a country..."
            color="blue-grey lighten-2"
            :hint="filter.countryWhitelist ? 'Whitelist' : 'Blacklist'"
            label=""
            multiple
          />
        </div>
      </div>

      <hr style="margin: 50px 0 50px 0" />

      <div class="row">
        <div class="col-lg-4">
          <label class="font-weight-bold font-size-h5">Device</label>
          <v-subheader
            v-text="'Select devices'"
          ></v-subheader>
        </div>

        <div class="col-lg-8">
          <!-- <b-form-checkbox class="whitelist-switch" v-model="filter.deviceWhitelist" switch>
            Whitelist
          </b-form-checkbox> -->
          <span
            class="switch switch-sm switch-outline whitelist-switch"
          >
            <div class="form-text mr-2 mt-0">Blacklist</div>
            <label><input
                type="checkbox"
                checked="checked"
                name="select"
                v-model="filter.deviceWhitelist"
            /><span></span></label>
            <div class="form-text ml-1 mt-0">Whitelist</div>
          </span>
          <v-autocomplete
            v-model="filter.device"
            :items="devices"
            item-text="text"
            item-value="value"
            label=""
            multiple
            chips
            :hint="filter.deviceWhitelist ? 'Whitelist' : 'Blacklist'"
            persistent-hint
            deletable-chips
            hide-details
            attach
            placeholder="Type a device..."
            color="blue-grey lighten-2"
          />
        </div>
      </div>


    </div>
<br>
    <div class="shedule-wrapper mb-10" v-if="activeTab == 'day_parting'">
      <div class="row mb-5">
        <label class="font-weight-bold font-size-h5">Dayparting settings</label>
      </div>

      <div class="row d-flex align-items-center">
        <div class="col-lg-4">
          <label>Select Timezone</label>
        </div>

        <div class="col-lg-8">
          <v-autocomplete
            v-model="filter.scheduleSettings.timezone"
            :items="timezones"
            persistent-hint
            hide-details
            attach
            color="blue-grey lighten-2"
            label=""
            outlined
            dense
          />
        </div>
      </div>

      <hr style="margin: 50px 0 50px 0" />

      <div class="row">
        <scheduler v-model="filter.schedule" multiple :startOfWeek="1" />
      </div>

      <div class="row">
        <b-alert v-if="scheduleBlockAll" class="mt-10" show variant="danger">Warning, You didn't select any schedule. All traffic will be cloaked.</b-alert>
      </div>


    </div>

    <div class="location-wrapper mb-10" v-if="activeTab == 'location'">

      <div class="row">
        <div class="col-lg-4">
          <label class="font-weight-bold font-size-h5">Country</label>
          <v-subheader
            v-text="'Select countries'"
          ></v-subheader>
        </div>

        <div class="col-lg-8">
          <!-- <b-form-checkbox class="whitelist-switch" v-model="filter.countryWhitelist" switch>
            Whitelist
          </b-form-checkbox> -->
          <span
            class="switch switch-sm switch-outline whitelist-switch"
          >
            <div class="form-text mr-2 mt-0">Blacklist</div>
            <label><input
                type="checkbox"
                checked="checked"
                name="select"
                v-model="filter.countryWhitelist"
            /><span></span></label>
            <div class="form-text ml-1 mt-0">Whitelist</div>
          </span>
          <v-autocomplete
            v-model="filter.country"
            :items="countries"
            persistent-hint
            chips
            deletable-chips
            hide-details
            attach
            placeholder="Type a country..."
            color="blue-grey lighten-2"
            :hint="filter.countryWhitelist ? 'Whitelist' : 'Blacklist'"
            label=""
            multiple
          />
        </div>
      </div>

      <hr style="margin: 50px 0 50px 0" />

      <div class="row">
        <div class="col-lg-4">
          <label class="font-weight-bold font-size-h5">Region</label>
          <v-subheader
            v-text="'Enter Region comma separated'"
          ></v-subheader>
        </div>

        <div class="col-lg-8">
          <!-- <b-form-checkbox class="whitelist-switch" v-model="filter.regionWhitelist" switch>
            Whitelist
          </b-form-checkbox> -->
          <span
            class="switch switch-sm switch-outline whitelist-switch"
          >
            <div class="form-text mr-2 mt-0">Blacklist</div>
            <label><input
                type="checkbox"
                checked="checked"
                name="select"
                v-model="filter.regionWhitelist"
            /><span></span></label>
            <div class="form-text ml-1 mt-0">Whitelist</div>
          </span>
          <v-text-field
            v-model="filter.region"
            :hint="filter.regionWhitelist ? 'Whitelist' : 'Blacklist'"
            persistent-hint
          ></v-text-field>
        </div>
      </div>

      <hr style="margin: 50px 0 50px 0" />

      <div class="row">
        <div class="col-lg-4">
          <label class="font-weight-bold font-size-h5">City</label>
          <v-subheader
          v-text="'Enter Cities comma separated'"
          ></v-subheader>
        </div>

        <div class="col-lg-8">
          <!-- <b-form-checkbox class="whitelist-switch" v-model="filter.cityWhitelist" switch>
            Whitelist
          </b-form-checkbox> -->
          <span
            class="switch switch-sm switch-outline whitelist-switch"
          >
            <div class="form-text mr-2 mt-0">Blacklist</div>
            <label><input
                type="checkbox"
                checked="checked"
                name="select"
                v-model="filter.cityWhitelist"
            /><span></span></label>
            <div class="form-text ml-1 mt-0">Whitelist</div>
          </span>
          <v-text-field
            v-model="filter.city"
            :hint="filter.cityWhitelist ? 'Whitelist' : 'Blacklist'"
            persistent-hint
          ></v-text-field>
        </div>
      </div>

      <hr style="margin: 50px 0 50px 0" />

      <div class="row">
        <div class="col-lg-4">
          <label class="font-weight-bold font-size-h5">Options</label>
        </div>
        <div class="col-lg-12">
          <v-app>
            <div class="d-flex align-items-center field-block">
              <div class="font-size-lg mr-5 field-title">Block if IP location city is not found:</div>
              <v-slider v-model="filter.options.noCity"
                :color="getColorByValue(filter.options.noCity)"
                :track-color="getColorByValue(filter.options.noCity)"
                :thumb-color="getColorByValue(filter.options.noCity)"
                :tick-labels="ticksLabels" :max="2" step="1" tick-size="0"
              ></v-slider>
            </div>
            <div class="d-flex align-items-center field-block">
              <div class="font-size-lg mr-5 field-title">Block if IP location region is not found:</div>
              <v-slider v-model="filter.options.noRegion"
                :color="getColorByValue(filter.options.noRegion)"
                :track-color="getColorByValue(filter.options.noRegion)"
                :thumb-color="getColorByValue(filter.options.noRegion)"
                :tick-labels="ticksLabels" :max="2" step="1" tick-size="0"
              ></v-slider>
            </div>
            <div class="d-flex align-items-center field-block">
              <div class="font-size-lg mr-5 field-title">Block if location city OR region is not found:</div>
              <v-slider v-model="filter.options.noRegionOrNoCity"
                :color="getColorByValue(filter.options.noRegionOrNoCity)"
                :track-color="getColorByValue(filter.options.noRegionOrNoCity)"
                :thumb-color="getColorByValue(filter.options.noRegionOrNoCity)"
                :tick-labels="ticksLabels" :max="2" step="1" tick-size="0"
              ></v-slider>
            </div>
            <div class="d-flex align-items-center field-block">
              <div class="font-size-lg mr-5 field-title">Block if location city AND region is not found:</div>
              <v-slider v-model="filter.options.noRegionAndNoCity"
                :color="getColorByValue(filter.options.noRegionAndNoCity)"
                :track-color="getColorByValue(filter.options.noRegionAndNoCity)"
                :thumb-color="getColorByValue(filter.options.noRegionAndNoCity)"
                :tick-labels="ticksLabels" :max="2" step="1" tick-size="0"
              ></v-slider>
            </div>
            <div class="d-flex align-items-center field-block">
              <div class="font-size-lg mr-5 field-title">Block if timezone don't match country:</div>
              <v-slider v-model="filter.options.timezone"
                :color="getColorByValue(filter.options.timezone)"
                :track-color="getColorByValue(filter.options.timezone)"
                :thumb-color="getColorByValue(filter.options.timezone)"
                :tick-labels="ticksLabels" :max="2" step="1" tick-size="0"
              ></v-slider>
            </div>
          </v-app>
        </div>
      </div>


    </div>

    <div class="device-wrapper mb-10" v-if="activeTab == 'device'">
      <div class="row">
        <div class="col-lg-4">
          <label class="font-weight-bold font-size-h5">Device</label>
          <v-subheader
            v-text="'Select devices'"
          ></v-subheader>
        </div>

        <div class="col-lg-8">
          <!-- <b-form-checkbox class="whitelist-switch" v-model="filter.deviceWhitelist" switch>
            Whitelist
          </b-form-checkbox> -->
          <span
            class="switch switch-sm switch-outline whitelist-switch"
          >
            <div class="form-text mr-2 mt-0">Blacklist</div>
            <label><input
                type="checkbox"
                checked="checked"
                name="select"
                v-model="filter.deviceWhitelist"
            /><span></span></label>
            <div class="form-text ml-1 mt-0">Whitelist</div>
          </span>
          <v-autocomplete
            v-model="filter.device"
            :items="devices"
            item-text="text"
            item-value="value"
            label=""
            multiple
            chips
            :hint="filter.deviceWhitelist ? 'Whitelist' : 'Blacklist'"
            persistent-hint
            deletable-chips
            hide-details
            attach
            placeholder="Type a device..."
            color="blue-grey lighten-2"
          />
        </div>
      </div>

      <hr style="margin: 50px 0 50px 0" />

      <div class="row">
        <div class="col-lg-4">
          <label class="font-weight-bold font-size-h5">OS</label>
          <v-subheader
            v-text="'Select systems'"
          ></v-subheader>
        </div>

        <div class="col-lg-8">
          <!-- <b-form-checkbox class="whitelist-switch" v-model="filter.osWhitelist" switch>
            Whitelist
          </b-form-checkbox> -->
          <span
            class="switch switch-sm switch-outline whitelist-switch"
          >
            <div class="form-text mr-2 mt-0">Blacklist</div>
            <label><input
                type="checkbox"
                checked="checked"
                name="select"
                v-model="filter.osWhitelist"
            /><span></span></label>
            <div class="form-text ml-1 mt-0">Whitelist</div>
          </span>
          <v-autocomplete
            v-model="filter.os"
            :items="os"
            item-text="text"
            item-value="value"
            label=""
            multiple
            chips
            :hint="filter.osWhitelist ? 'Whitelist' : 'Blacklist'"
            persistent-hint
            deletable-chips
            hide-details
            attach
            placeholder="Type a os..."
            color="blue-grey lighten-2"
          />
        </div>
      </div>

      <hr style="margin: 50px 0 50px 0" />

      <div class="row">
        <div class="col-lg-4">
          <label class="font-weight-bold font-size-h5">Browser</label>
          <v-subheader
            v-text="'Select browsers'"
          ></v-subheader>
        </div>

        <div class="col-lg-8">
          <!-- <b-form-checkbox class="whitelist-switch" v-model="filter.browserWhitelist" switch>
            Whitelist
          </b-form-checkbox> -->
          <span
            class="switch switch-sm switch-outline whitelist-switch"
          >
            <div class="form-text mr-2 mt-0">Blacklist</div>
            <label><input
                type="checkbox"
                checked="checked"
                name="select"
                v-model="filter.browserWhitelist"
            /><span></span></label>
            <div class="form-text ml-1 mt-0">Whitelist</div>
          </span>
          <v-autocomplete
            v-model="filter.browser"
            :items="browsers"
            item-text="text"
            item-value="value"
            label=""
            multiple
            chips
            :hint="filter.browserWhitelist ? 'Whitelist' : 'Blacklist'"
            persistent-hint
            deletable-chips
            hide-details
            attach
            placeholder="Type a browser..."
            color="blue-grey lighten-2"
          />
        </div>
      </div>
    </div>
    <div class="connection-wrapper mb-10" v-if="activeTab == 'connection'">
      <div class="row">
        <div class="col-lg-4">
          <label class="font-weight-bold font-size-h5">Options</label>
        </div>
        <div class="col-lg-12">
          <v-app>
            <div class="d-flex align-items-center field-block">
              <div class="font-size-lg mr-5 field-title">Block empty referer:</div>
              <v-slider
                v-model="filter.options.emptyReferer" :tick-labels="ticksLabels" :max="2" step="1" tick-size="0"
                :color="getColorByValue(filter.options.emptyReferer)"
                :track-color="getColorByValue(filter.options.emptyReferer)"
                :thumb-color="getColorByValue(filter.options.emptyReferer)"
              ></v-slider>
            </div>
            <div class="d-flex align-items-center field-block">
              <div class="font-size-lg mr-5 field-title">Block ip is ipv6:</div>
              <v-slider
                v-model="filter.options.blockIPv6" :tick-labels="ticksLabels" :max="2" step="1" tick-size="0"
                :color="getColorByValue(filter.options.blockIPv6)"
                :track-color="getColorByValue(filter.options.blockIPv6)"
                :thumb-color="getColorByValue(filter.options.blockIPv6)"
              ></v-slider>
            </div>
            <div class="d-flex align-items-center field-block">
              <div class="font-size-lg mr-5 field-title">Block desktop connected on mobile hotspot:</div>
              <v-slider
                v-model="filter.options.blockDesktopOnMobileModem" :tick-labels="ticksLabels" :max="2" step="1" tick-size="0"
                :color="getColorByValue(filter.options.blockDesktopOnMobileModem)"
                :track-color="getColorByValue(filter.options.blockDesktopOnMobileModem)"
                :thumb-color="getColorByValue(filter.options.blockDesktopOnMobileModem)"
              ></v-slider>
            </div>
          </v-app>
        </div>
      </div>

      <hr style="margin: 50px 0 50px 0" />

      <div class="row">
        <div class="col-lg-4">
          <label class="font-weight-bold font-size-h5">IP</label>
          <v-subheader
          >
          <span>Enter list of IPs<br>
             Regex format<br>
          <a style="color:black; text-decoration:underline" target="_blank" href="https://regexr.com/">Test here</a></span>
        </v-subheader>
        </div>

        <div class="col-lg-8">
          <!-- <b-form-checkbox class="whitelist-switch" v-model="filter.ipWhitelist" switch>
            Whitelist
          </b-form-checkbox> -->
          <span
            class="switch switch-sm switch-outline whitelist-switch"
          >
            <div class="form-text mr-2 mt-0">Blacklist</div>
            <label><input
                type="checkbox"
                checked="checked"
                name="select"
                v-model="filter.ipWhitelist"
            /><span></span></label>
            <div class="form-text ml-1 mt-0">Whitelist</div>
          </span>
          <v-textarea
            class="mt-2"
            v-model="filter.ip"
            :hint="filter.ipWhitelist ? 'Whitelist' : 'Blacklist'"
            no-resize
            placeholder="176.112.*"
            persistent-hint
            outlined
          ></v-textarea>
        </div>
      </div>

      <hr style="margin: 50px 0 50px 0" />

     <div class="row">
        <div class="col-lg-4">
          <label class="font-weight-bold font-size-h5">URL</label>
          <v-subheader>
          <span>Enter URL in Regex format. <br>
          <a style="color:black; text-decoration:underline" target="_blank" href="https://regexr.com/">Test here</a></span>
          </v-subheader>
        </div>
        <div class="col-lg-8">
          <span
            class="switch switch-sm switch-outline whitelist-switch"
          >
            <div class="form-text mr-2 mt-0">Blacklist</div>
            <label><input
                type="checkbox"
                checked="checked"
                name="select"
                v-model="filter.urlWhitelist"
            /><span></span></label>
            <div class="form-text ml-1 mt-0">Whitelist</div>
          </span>
          <v-textarea
            v-model="filter.url"
            :hint="filter.urlWhitelist ? 'Whitelist' : 'Blacklist'"
            persistent-hint
            no-resize
            placeholder="gclid=*"

            outlined
            class="mt-2"
          ></v-textarea>
        </div>
      </div>


      <hr style="margin: 50px 0 50px 0" />

      <div class="row">
        <div class="col-lg-4">
          <label class="font-weight-bold font-size-h5">Referer</label>
          <v-subheader>
          <span>Enter Referer in Regex format. <br>
          <a style="color:black; text-decoration:underline" target="_blank" href="https://regexr.com/">Test here</a></span>
          </v-subheader>

        </div>

        <div class="col-lg-8">

          <span
            class="switch switch-sm switch-outline whitelist-switch"
          >
            <div class="form-text mr-2 mt-0">Blacklist</div>
            <label><input
                type="checkbox"
                checked="checked"
                name="select"
                v-model="filter.refererWhitelist"
            /><span></span></label>
            <div class="form-text ml-1 mt-0">Whitelist</div>
          </span>
          <v-textarea
            v-model="filter.referer"
            placeholder="google*"
            :hint="filter.refererWhitelist ? 'Whitelist' : 'Blacklist'"
            persistent-hint
            no-resize
            outlined
            class="mt-2"
          ></v-textarea>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import VueTimepicker from 'vue2-timepicker'
import RoundSlider from 'vue-round-slider'
import timezones from 'timezones-list';
import VueScheduler from '@duoa/vue-scheduler'
import '@duoa/vue-scheduler/dist/vue-scheduler.css'

const filteredTimezones = [...new Set(timezones.map(it => `GMT ${it.utc}`))]

import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
  props: {
    filter: Object
  },
  components: {
    // VueTimepicker,
    RoundSlider,
    'scheduler': VueScheduler
  },
  filters: {
    capitalize(s) {
      if (typeof s !== 'string') {
        return ''
      }
      return s.charAt(0).toUpperCase() + s.slice(1)
    }
  },
  data: () => ({
    countries: [],
    devices: ["desktop", "mobile", "tablet"],
    scores: ["Off", "Weak", "Normal", "Strong", "Strong+"],
    os: ['Mac OS', 'Windows', 'Linux','Android','iOS'],
    browsers: ['Chrome', 'Safari','Firefox','Internet Explorer'],
    languages: ['en','fr','de','nl'],
    languagesStrict: ['en-US'],
    activeTab: 'basic',
    timezones: filteredTimezones,
    visits: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', 'no limit'],
    ticksLabels: ['Block', 'Auto', 'Allow'],
  }),
  created() {
    this.countries = this.appConfig('countries') || [];

    const languages = (this.appConfig('languages') || []);
    this.languages = Object.keys(languages).map(key => ({ value: key, text: languages[key] }))

    const languagesStrict = (this.appConfig('languagesStrict') || []);
    this.languagesStrict = Object.keys(languagesStrict).map(key => ({ value: key, text: languagesStrict[key] }))
  },
  computed: {
    ...mapGetters(["appConfig"]),
    visitsCount() {
      if (this.filter.visit_allowed == 11) {
        return 'No limit'
      }

      return this.filter.visit_allowed;
    },
    tabName() {
      let tabName = 'Basic';
      switch(this.activeTab) {
        case 'basic':
          tabName = 'Basic';
          break;
        case 'day_parting':
          tabName = 'Basic';
          break;
        case 'location':
          tabName = 'Advanced';
          break;
        case 'device':
          tabName = 'Advanced';
          break;
        case 'connection':
          tabName = 'Advanced';
          break;
        default:
          break;
      }
      return tabName;
    },

    scheduleBlockAll() {
      if (!this.filter.schedule) return false;

      return !Object.keys(this.filter.schedule).length;
    },

    scoreInfo() {
      if (this.filter.score >= 75) {
        return `
          <span>
            <h2>Maximal Protection</h2>
            <ul>
              <li>Advanced Fingerprint history</li>
              <li>Advanced IP check</li>
              <li>Block if IP location city <u>AND</u> region not found</li>
              <li>Advanced Timezone check</li>
              <li>Block desktop connected on mobile hotspot</li>
              <li>Block Headless browser and virtual device </li>
              <li>Block if Language/Location don't match</li>
              <li>Block empty referer</li>
            </ul>
          </span>
        `;
      } else if (this.filter.score >= 50) {
        return `
          <span>
            <h2>High Protection <span style="font-size:15px">(recommended)</span></h2>
            <ul>
              <li>Advanced Fingerprint history</li>
              <li>Advanced IP check</li>
              <li>Block if IP location city <u>OR</u> region not found</li>
              <li>Advanced Timezone check</li>
              <li>Block desktop connected on mobile hotspot</li>
              <li>Block Headless browser and virtual device </li>
              <li>Block if Language/Location don't match</li>
            </ul>
          </span>
        `;
      } else if (this.filter.score >= 25) {
        return `
          <h2>Normal Protection</h2>
          Filter traffic based on your settings +
          <ul>
            <li>Fingerprint history</li>
            <li>IP check</li>
            <li>Block Obvious bot</li>
          </ul>
          </span>
        `;
      } else {
        return `
          <span>
            <h2>Low Protection</h2>
            Only filter traffic based on your settings
          </span>
        `;
      }
    }
  },
  methods: {
    changeActiveTab(tabName) {
      this.activeTab = tabName;
    },
    changeDayWhitelist(event, day) {
      this.filter.schedule = JSON.parse(JSON.stringify({
        ...this.filter.schedule,
        [day]: {
          ...this.filter.schedule[day],
          whitelist: event
        }
      }))
    },
    getColorByValue(value) {
      const assoc = {
        0: '#F64E60',
        1: 'info',
        2: 'success'
      };

      return assoc[value];
    }
  }
}
</script>

<style scoped>
.whitelist-switch {
  margin-left: auto;
  width: max-content;
}

::v-deep .rs-path {
  stroke-width: 6px;
  stroke-dasharray: 2 6;
  opacity: 0.7;
}

.switch input:empty ~ span:after {
  background-color: #000;
}

.switch input:checked ~ span:after {
  background-color: #fff;
}
.dayparting-switch input:empty ~ span:after {
  background-color: #fff;
}
.dayparting-switch input:checked ~ span:after {
  background-color: #000;
}

::v-deep .scheduler-active {
  background-color: #1bc5bd !important;
}

::v-deep .v-input--radio-group label {
  margin-bottom: 0;
  margin-left: 4px;
  font-size: 14px;
}
.field-block {
  /* justify-content: space-around; */
}
.field-block .field-title {
  width: 320px;
}

.field-block .v-input__slider {
  max-width: 150px;
  min-width: 150px;
}

::v-deep .field-block .v-input__slider .v-slider__tick-label {
  font-size: 13px !important;
}
</style>
