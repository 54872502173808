<template>
  <div class="card card-custom" data-app>
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div
        class="wizard wizard-2"
        id="kt_wizard_v2"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <WizardStepNavbar :submit="submit" :state="state" />

        <!--begin: Wizard Body -->
        <div class="wizard-body py-8 px-8 py-lg-20 px-lg-10">
          <!--begin: Wizard Form-->
          <div class="row">
            <div class="offset-xxl-2 col-xxl-8">
              <form class="form" id="kt_form">
                <!--begin: Wizard Step 1-->
                <WizardCampaignStep :campaign="campaign"/>
                <!--end: Wizard Step 1-->

                <!--begin: Wizard Step 2-->
                <WizardFilterStep
                  :filter="filter"
                />
                <!--end: Wizard Step 2-->


                <!--begin: Wizard Step 3-->
                <WizardMoneyStep
                  :money="money"
                  :changeMoneyPageFile="changeMoneyPageFile"
                  :queryMoney="queryMoney"
                  :campaigns="campaigns"
                />
                <!--end: Wizard Step 3-->

                <!--begin: Wizard Step 4-->
                <WizardSafeStep
                  :safe="safe"
                  :querySafe="querySafe"
                />
                <!--end: Wizard Step 4 -->

                <!--start: Wizard Step 5 -->
                <!-- <WizardOfferStep
                  :offer="offer"
                  :offerCountries="offerCountries"
                  :changeOfferCountry="changeOfferCountry"
                  :changeOffer="changeOffer"
                  :offers="offers"
                  :campaigns="campaigns"
                /> -->
                <!--end: Wizard Step 5 -->

                <!--end: Wizard Step 6-->
                <WizardInstallationStep
                  :campaign="campaign"
                  :installation="installation"
                />
                <!--end: Wizard Step 6-->

                <!--begin: Wizard Step 6-->
                <WizardReviewStep
                  :campaign="campaign"
                  :filter="filter"
                  :safeInfo="safeInfo"
                  :moneyInfo="moneyInfo"
                  :installInfo="installInfo"
                  :installation="installation"
                  :userdata="userdata"
                  :debugInfo="debugInfo"
                  :onSuccessCopy="onSuccessCopy"
                />
                <!--end: Wizard Step 6-->

                <!--begin: Wizard Actions -->
                <WizardStepActions :submit="submit" />
                <!--end: Wizard Actions -->
              </form>
            </div>
            <!--end: Wizard-->
          </div>
        </div>
        <!--end: Wizard Body -->
      </div>
      <!--end: Wizard-->
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-2.scss";
</style>

<script>
import Vue from 'vue';
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";

import projectData from '@/core/config/data'

import WizardStepNavbar from '@/view/components/Campaign/WizardStepNavbar'
import WizardStepActions from '@/view/components/Campaign/WizardStepActions'
import WizardCampaignStep from '@/view/components/Campaign/WizardCampaignStep'
import WizardFilterStep from '@/view/components/Campaign/WizardFilterStep'
import WizardMoneyStep from '@/view/components/Campaign/WizardMoneyStep'
import WizardSafeStep from '@/view/components/Campaign/WizardSafeStep'
// import WizardOfferStep from '@/view/components/Campaign/WizardOfferStep'
import WizardInstallationStep from '@/view/components/Campaign/WizardInstallationStep'
import WizardReviewStep from '@/view/components/Campaign/WizardReviewStep'

const dataSnapshot = () => ({
  valid: true,
  list: {
    campaign: [],
    money: [],
    safe: [],
    filter: [],
    offer: []
  },
  campaign: {
    name: '',
    url: '',
    mode: '',
    status: 'active'
  },
  money: [{
    id: 1,
    name: '',
    url: '',
    mode: 'iframe',
    new: 'true',
    query: false,
    host: false,
    file: null,
    selection: 'new',
    nojs: false,
    rotation: 100,
    code_head: '<script><\/script>',
    code_body: '<script><\/script>',
  }],
  installation: {
    js: "",
    dns: "",
    api : ""
  },
  safe: {
    name: '',
    url: '',
    mode: 'stay',
    anotherMode: 'reverse proxy',
    new: 'true',
    query: false,
  },
  filter: {
    score: 0,
    visit_allowed: 11,
    device: [],
    country: [],
    os: [],
    region: '',
    city: '',
    browser: [],
    ip: '',
    url: '',
    referer: '',
    countryWhitelist: true,
    deviceWhitelist: true,
    osWhitelist: true,
    regionWhitelist: true,
    cityWhitelist: true,
    browserWhitelist: true,
    ipWhitelist: true,
    urlWhitelist: true,
    refererWhitelist: true,
    additionalDeviceConf: false,
    additionalLocationConf: false,
    additionalConnectionConf: false,
    additionalScheduleConf: false,
    schedule: {
      0: [...Array(24).keys()],
      1: [...Array(24).keys()],
      2: [...Array(24).keys()],
      3: [...Array(24).keys()],
      4: [...Array(24).keys()],
      5: [...Array(24).keys()],
      6: [...Array(24).keys()]
    },
    options: {
      blockDesktopOnMobileModem: 1,
      noRegionOrNoCity: 1,
      noCity: 1,
      noRegionAndNoCity: 1,
      noRegion: 1,
      emptyReferer: 1,
      blockIPv6: 1,
      timezone: 1
    },
    scheduleSettings: {
      timezone: "GMT +00:00"
    },
    days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  },
  user: {
    base: '',
    'secret key': '',
    'apikey': '',
    'api': ''
  }
});

export default {
  name: "Wizard",
  components: {
    WizardStepNavbar,
    WizardCampaignStep,
    WizardFilterStep,
    WizardMoneyStep,
    WizardSafeStep,
    // WizardOfferStep,
    WizardInstallationStep,
    WizardReviewStep,
    WizardStepActions
  },
  data() {
    return {
      ...dataSnapshot(),
      loadedCampaignData: false,
      state: null,
      stateID: null,
      baseUrl: 'http://localhost:3000',
      landers: [],
      offers: [],
      campaigns: [],
      landerCountries: [],
      offerCountries: []
    };
  },
  created() {
    this.offerCountries = this.appConfig('offerCountries') || [];
    this.landerCountries = this.appConfig('landerCountries') || [];

    if (this.$route.params.id) {
      this.campaign.id = this.$route.params.id;
      this.getCampaignData()
    } else {
      this.campaign.generatedId = this.generateID(6)

      if (this.$route.query && this.$route.query.url) {
        this.campaign.url = this.$route.query.url
      }

      this.loadedCampaignData = true;
    }
  },
  mounted() {
    this.baseUrl = Vue.axios.defaults.baseURL;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "New", route: "wizard-1" },
      { title: "Campaign" }
    ]);

    // Initialize form wizard
    const wizard = new KTWizard("kt_wizard_v2", {
      startStep: 1, // initial active step number
      clickableSteps: true // allow step clicking
    });

    // Validation before going to next page
    wizard.on("beforeNext", function(/*wizardObj*/) {
      // validate the form and use below function to stop the wizard's step
      // wizardObj.stop();
    });

    // Change event
    wizard.on("change", function(/*wizardObj*/) {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });

    this.setCodes();

    this.$nextTick(() => {
      // this.getCampaignDataList()
    })
  },
  methods: {
    async changeMoneyPageFile(index) {
      if (!this.money[index].file) return;

      const fileUploadResult = await this.uploadMoneyFile(index);

      if (fileUploadResult.errors || !fileUploadResult.url) {
        Swal.fire({
          title: "",
          text: fileUploadResult.errors && fileUploadResult.errors[0] || 'Unknown error',
          icon: "error",
          confirmButtonClass: "btn btn-secondary"
        });

        this.money[index].file = null;

        return;
      }

      Swal.fire({
        title: "",
        text: `File has been successfully uploaded!`,
        icon: "success",
        confirmButtonClass: "btn btn-secondary"
      });

      this.money[index].preview = fileUploadResult.url;

      // Perhaps this does not need to be done, but let it be here for now =)
      this.money[index].url = fileUploadResult.url;
    },

    onSuccessCopy() {
      Swal.fire({
        title: "",
        text: `Code has been successfully copied!`,
        icon: "success",
        confirmButtonClass: "btn btn-secondary",
        timer: 1000
      });
    },

    async getCampaignDataList() {
      const data = await ApiService.query(`data/campaign`)
        .then(res => res.data)
        .catch(err => {})

      if (data) {
        this.campaigns = data.filter(it => it.id !== this.campaign.id);
      }
    },

    queryMoney(index){
      if(this.money[index].query && !this.money[index].url.includes("{query}")){
        if(this.money[index].url.includes("?")){
          this.money[index].url = `${this.money[index].url}&{query}`
        } else {
          this.money[index].url = `${this.money[index].url}?{query}`
        }
      } else if(!this.money[index].query && this.money[index].url.includes("?{query}")){
        this.money[index].url = this.money[index].url.replace("?{query}","")
      } else if(!this.money[index].query && this.money[index].url.includes("&{query}")){
        this.money[index].url = this.money[index].url.replace("&{query}","")
      }

      if (this.money[index].protect && !this.money[index].url.includes("token=")) {
        if(this.money[index].url.includes("?")){
          this.money[index].url = `${this.money[index].url}&token={token}`
        } else {
          this.money[index].url = `${this.money[index].url}?token={token}`
        }
      } else {
        this.money[index].url = this.money[index].url.replace('?token={token}', '')
        this.money[index].url = this.money[index].url.replace('&token={token}', '')
      }
    },

    querySafe(){
      if(this.safe.query && !this.safe.url.includes("{query}")){
        if(this.safe.url.includes("?")){
          this.safe.url = `${this.safe.url}&{query}`
        } else {
          this.safe.url = `${this.safe.url}?{query}`
        }
      } else if(!this.safe.query && this.safe.url.includes("?{query}")){
        this.safe.url = this.safe.url.replace("?{query}","")
      } else if(!this.safe.query && this.safe.url.includes("&{query}")){
        this.safe.url = this.safe.url.replace("&{query}","")
      }
    },

    submit: async function(e, autoSave = false) {
      e.preventDefault();

      if ((autoSave && !this.campaign.id) || !this.loadedCampaignData) return;

      if (autoSave) {
        this.state = 'loading';
      }

      this.filter.name = `filter ${this.campaign.name} ${this.filter.score}`

      const data = {
        id: this.campaign.id || '',
        generatedId: this.campaign.generatedId,
        base: this.userdata.base,
        installation: this.installation,
        email: this.userdata.email,
        filter: this.filter,
        campaign: this.campaign,
        money: this.money,
        safe: this.safe
      }

      const result = await ApiService.post(`campaign`, data)
        .then(res => res.data)
        .catch(err => err.response.data)
        .finally(() => {
          this.state = 'saved';
          const stateID = Math.random().toString(36).substr(2, 9);

          this.stateID = stateID;

          setTimeout(() => {
            if (this.stateID === stateID) {
              this.state = null;
            }
          }, 5000)
        })

      if (!autoSave) {
        if (result.errors) {
          Swal.fire({
            title: "",
            text: result.errors[0],
            icon: "error",
            confirmButtonClass: "btn btn-secondary"
          });
          return;
        }

        const text = this.campaign.id ? 'updated' : 'created';

        Swal.fire({
          title: "",
          text: `Campaign has been successfully ${text}!`,
          icon: "success",
          confirmButtonClass: "btn btn-secondary"
        });

        setTimeout(() => {
          this.$router.push({ name: 'dashboard' })
        }, 1000)
      }
    },

    selectSafe(value){
      this.safe.url = ""
      this.safe.new = "false"
    },

    selectMoney(value){
      this.money.url = ""
      this.money.new = "false"
    },

    async getPage(){
      let response = await ApiService.get(`page`)

      for (let page of response.data){
        if (page.fields.type == "money") {
          this.list.money = this.list.money.concat(page.fields.name)
        }

        if (page.fields.type == "safe") {
          this.list.safe = this.list.safe.concat(page.fields.name)
        }

      }
    },

    async getCampaignData(){
      const response = await ApiService.get(`campaign/${this.$route.params.id}`)
        .then(res => {
          if (res.status == 200 && res.data) {
            return res.data
          }
        })

      if (!response) {
        this.$router.push({ name: 'new-campaign' })
      }

      this.handleCampaignData(response);

      if (this.campaigns.length) {
        this.campaigns = this.campaigns.filter(it => it.id !== this.campaign.id);
      }

      this.loadedCampaignData = true;
    },

    handleCampaignData(data) {
      this.campaign.id = data.id;
      this.campaign.name = data.name;
      this.campaign.url = data.url;
      this.campaign.mode = data.mode;
      this.campaign.status = data.status;

      const filterKeys = [
        'country', 'device', 'os', 'region', 'city',
        'browser', 'ip', 'url', 'referer'
      ];

      const textFields = ['region', 'city'];
      const textareaFields = ['ip', 'referer', 'url'];

      filterKeys.forEach(key => {
        const frontKey = key;
        this.filter[`${frontKey}Whitelist`] = data.filter[`${key}_allowed`] !== "";

        const keyData = this.filter[`${frontKey}Whitelist`] ? data.filter[`${key}_allowed`] : data.filter[`${key}_disallowed`];

        if (textFields.includes(key)) {
          this.filter[frontKey] = (keyData || []).join(', ')
        } else if (textareaFields.includes(key)) {
          this.filter[frontKey] = (keyData || []).join('\n')
        } else {
          this.filter[frontKey] = keyData;
        }
      })

      this.filter.additionalDeviceConf = data.builder.filter && data.builder.filter.additionalDeviceConf || false;
      this.filter.additionalLocationConf = data.builder.filter && data.builder.filter.additionalLocationConf || false;
      this.filter.additionalConnectionConf = data.builder.filter && data.builder.filter.additionalConnectionConf || false;
      this.filter.additionalScheduleConf = data.builder.filter && data.builder.filter.additionalScheduleConf || false;

      this.filter.score = data.filter && data.filter.score || 0;
      this.filter.visit_allowed = data.filter && data.filter.visit_allowed || 11;

      const optionsAssoc = { 'block': 0, 'auto': 1, 'allow': 2 };

      if (data.filter.options && Object.keys(data.filter.options).length) {
        const result = {};
        Object.keys(data.filter.options).forEach(key => {
          result[key] = optionsAssoc[data.filter.options[key]];
        });

        this.filter.options = result;
      }

      if (data.filter.schedule) {
        this.filter.scheduleSettings.timezone = data.filter.schedule.timezone || null;

        Object.keys(data.filter.schedule).forEach(day => {
          const key = this.filter.days.findIndex(it => it == day);

          if (key !== -1) {
            this.filter.schedule[key] = data.filter.schedule[day];
          }
        })
      }

      data.money.forEach((moneyData, index) => {
        const moneyBuilderData = data.builder.money[index];

        if (!this.money[index]) {
          this.money[index] = {
            selection: 'new',
            protect: false
          }
        }

        this.money[index].id = Math.random().toString(36).substr(2, 9);

        this.money[index].name = moneyData && moneyData.name || '';
        this.money[index].url = moneyData && moneyData.url || '';
        this.money[index].nojs = moneyData && moneyData.nojs || false;
        this.money[index].rotation = moneyData && moneyData.rotation || 0;
        this.money[index].code_head = moneyData && moneyData.code_head || '<script><\/script>';
        this.money[index].code_body = moneyData && moneyData.code_body || '<script><\/script>';
        this.money[index].selection = moneyBuilderData && moneyBuilderData.selection || '';
         this.money[index].protect = false;

        if (this.money[index].url && this.money[index].url.includes('?')) {
          this.money[index].query = true;

          if (this.money[index].url.includes('token=')) {
            this.money[index].protect = true;
          }
        }

        if (moneyData.preview) {
          this.money[index].preview = moneyData.preview;
          this.money[index].host = true;
        }

        this.money[index].mode = moneyData && moneyData.mode || 'iframe';
      })

      this.safe.name = data.safe && data.safe.name || '';
      this.safe.url = data.safe && data.safe.url || '';
      if (this.safe.url && this.safe.url.includes('?')) {
        this.safe.query = true;
      }

      if (data.safe && data.safe.mode !== 'stay') {
        this.safe.anotherMode = data.safe.mode;
        this.safe.mode = 'reverse proxy';
      }
    },

    handleUpload() {
      this.money.file = this.$refs.file.files[0]
    },
    async uploadMoneyFile(index) {
      const formData = new FormData()
      formData.append("moneypage_file", this.money[index].file)

      return await ApiService.post('campaign/upload/moneypage', formData, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
      }).then(res => res.data)
      .catch(err => err.response.data)
    },
    setCodes() {
      this.installation.js = projectData.js(this.userdata.apikey, this.userdata.api, this.campaign.id || this.campaign.generatedId)
      this.installation.api = projectData.api(this.userdata.apikey, this.userdata.api, this.campaign.id || this.campaign.generatedId)
      this.installation.dns = projectData.dns(this.userdata.apikey, this.userdata.api, this.campaign.id || this.campaign.generatedId)
    },

    generateID(length) {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;

      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }

      return result;
    }
  },
  computed: {
    ...mapGetters(["userdata", "appConfig"]),
    safeInfo() {
      if ( this.safe.mode == "stay"){
        return `Bot will stay on the same page ${this.safe.name} <a href="${this.campaign.url}" target="_blank">${this.campaign.url}</a>`
      } else if ( this.safe.mode == "reverse proxy"){
        return `Bot will see content of this page ${this.safe.name} <a href="${this.safe.url}" target="_blank">${this.safe.url}</a>  without redirect`
      } else if ( this.safe.mode == "redirect"){
        return `Bot are redirected to this page ${this.safe.name} <a href="${this.safe.url}" target="_blank"> ${this.safe.url}</a>`
      } else {
        return ""
      }
    },
    moneyInfo() {
      const money = this.money && this.money[0] || {};
  
      if ( money.mode == "iframe"){
        return `Real visitors will see content of money page ${money.name} <a href="${money.url}" target="_blank"> ${money.url}</a> in an iframe`
      } else if ( money.mode == "reverse proxy"){
        return `Real visitors will see content of money page ${money.name} <a href="${money.url}" target="_blank"> ${money.url}</a> without redirect ( check if layout issue )`
      } else if ( money.mode == "redirect"){
        return `Real visitors are to be redirected to money page ${money.name} <a href="${money.url}" target="_blank">${money.url}</a>`
      } else if ( money.name != ""){
        return `Real visitors will see ${money.name}`
      } else {
        return ""
      }
    },
    installInfo() {
      if (this.campaign.mode == "JS") {
        return `Place the JS code in the head tag of your page.<br> <code></code><br><a href="https://docs.cloakone.com/cloaker/installation/js" target="_blank">Click here for more instructions</a>`
      } else if ( this.campaign.mode == "DNS"){
        return `Install the cloaker on your domain with cloudflare. <a href="https://docs.cloakone.com/cloaker/installation/dns" target="_blank">Click here for more instructions</a>`

      } else {
        return ""
      }
    },
    debugInfo() {
      if (this.campaign.mode == "JS") {
        return `Once you place the js code in your page, you can use your secretkey to debug`
      } else if ( this.campaign.mode == "DNS"){
        return `Once you installed the cloaker on your cloudflare, you can use your secretkey to debug`
      } else {
        return ""
      }
    }
  }
};
</script>

<style>
a {
  color:#0d827c
}

.code-wrapper {
  position: relative;
}

.code-wrapper .copy-btn {
  display: block;
  position: absolute;
  right: 9px;
  top: -5px;
  margin: 11px auto;
  max-width: 500px;
  z-index: 1;
  -webkit-box-shadow: 0px 0px 11px -1px #000;
  box-shadow: 0px 0px 6px -2px #000;
  padding: 5px 10px;
  color: #fff;
  font-size: 10px;
  background: #1ac5bd;
  font-weight: 600;
  border-radius: 4px;
  text-transform: uppercase;
}

.codehighlight {
  display: inline-grid;
  /* overflow-x: scroll; */
  padding: 0;
  border-radius: 5px;
}

.codehighlight::-webkit-scrollbar {
  width: 12px;
}
.codehighlight::-webkit-scrollbar-track {
  background: #e2e2e2;
  border-radius: 20px;
}
.codehighlight::-webkit-scrollbar-thumb {
  background-color: #8b8b8b;
  border-radius: 20px;
  border: 3px solid #e2e2e2;
}

div pre[class*="language-"] {
  display: block !important;
  padding-right: 10px;
  margin: 0 auto;
}

</style>
