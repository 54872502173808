<template>
  <div class="pb-5" data-wizard-type="step-content">
    <h4 class="mb-10 font-weight-bold text-dark">
      Review your Details and Save
    </h4>
    <div class="border-bottom mb-5 pb-5">
      <div class="font-weight-bold mb-3">
        Campaign Details:
      </div>
      <div class="line-height-md">
        Campaign name: {{campaign.name}}
        <br />
        <span class="d-flex align-items-center">
          Your Campaign URL is : {{completeUrl}} <br />
          <button
            class="ml-3"
            type="button"
            v-clipboard:copy="completeUrl"
          >
            <v-icon small>content_copy</v-icon> Copy
          </button>
        </span>
      </div>
    </div>
    <div class="border-bottom mb-5 pb-5">
      <div class="font-weight-bold mb-3">
        Filter Settings:
      </div>
      <div class="line-height-md">
        <span v-if="filter.device">
          Devices {{filter.deviceWhitelist ? 'allowed': 'disallowed'}}: {{filter.device.length ? filter.device.join(', ') : 'all'}}<br>
        </span>
        <span v-if="filter.country">
          Countries {{filter.countryWhitelist ? 'allowed': 'disallowed'}}: {{filter.country.length ? filter.country.join(', ') : 'all'}}<br>
        </span>

        Filter score: {{filter.score}}

        <div class="dayparting mt-3">
          <div class="dayparing-title mb-2">Traffic allowed:</div>
          <div class="dayparting-body mb-2" v-html="daypartingText"></div>
          <div class="dayparing-timezone mb-2">Timezone selected: {{filter.scheduleSettings.timezone}}</div>
        </div>
      </div>
    </div>
    <div class="border-bottom mb-5 pb-5">
      <div class="font-weight-bold mb-3">
        Safe Page:
      </div>
      <div class="line-height-md">
        <div v-html="safeInfo"></div>
      </div>
    </div>

    <div class="border-bottom mb-5 pb-5">
      <div class="font-weight-bold mb-3">
        Money Page:
      </div>
      <div class="line-height-md">
        <div v-html="moneyInfo"></div>
      </div>
    </div>

    <div class="">
      <div class="font-weight-bold mb-3">
        Installation:
      </div>
      <div class="line-height-md">
        Cloaking mode : {{campaign.mode}}
        <br>
        <div v-html="installInfo"></div>
      </div>
      <div class="row code-wrapper mt-3 px-5" v-if="campaign.mode" >
        <code-highlight ref="codehighlight" class="col-12 codehighlight" language="javascript" :key="campaign.mode">
          {{installation[campaign.mode]}}
        </code-highlight>
        <button type="button" style="right: 23px;" class="copy-btn"
          v-clipboard:copy="installation[campaign.mode]"
          v-clipboard:success="onSuccessCopy"
        >Copy</button>
      </div>
    </div>

    <div class="mb-5" v-if="campaign.url" >
      <hr>
      <div class="font-weight-bold mb-3">
        Testing:
      </div>
      <div class="line-height-md">
        <div v-html="debugInfo"></div><br>
        Your secret key is <code>{{userdata.secretkey}}</code><br><br>
        List of command <br>
        <div class="row gutter-b mt-4">
          <div class="col-4">
            <a
              class="btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-10 px-5"
              :href="`${campaign.url}?c=${campaign.id || campaign.generatedId}&${userdata.secretkey}=debug`" target="_blank"
            >
              <span class="svg-icon svg-icon-3x svg-icon-primary m-0">
                <v-icon class="pr-0">bug_report</v-icon>
              </span>
              <span class="d-block font-weight-bold font-size-h6 mt-2">Test installation</span>
            </a>
          </div>
          <div class="col-4" v-if="campaign.id">
            <a
              class="btn btn-block btn-light btn-hover-success text-dark-50 text-center py-10 px-5"
              :href="`${campaign.url}?c=${campaign.id || campaign.generatedId}&${userdata.secretkey}=bypass`" target="_blank"
            >
              <span class="svg-icon svg-icon-3x svg-icon-primary m-0">
                <v-icon class="pr-0">paid</v-icon>
              </span>
              <span class="d-block font-weight-bold font-size-h6 mt-2">See money page</span>
            </a>
          </div>
          <div class="col-4">
            <router-link
              class="btn btn-block btn-light btn-hover-danger text-dark-50 text-center py-10 px-5"
              :to="'/stats/campaign/' + campaign.id"
            >
              <span class="svg-icon svg-icon-3x svg-icon-primary m-0">
                <v-icon class="pr-0">analytics</v-icon>
              </span>
              <span class="d-block font-weight-bold font-size-h6 mt-2">Open click report</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CodeHighlight from "vue-code-highlight/src/CodeHighlight.vue";
import "vue-code-highlight/themes/duotone-sea.css";
import "vue-code-highlight/themes/window.css";

export default {
  components: {
    CodeHighlight
  },
  props: {
    campaign: Object,
    filter: Object,
    safeInfo: String,
    moneyInfo: String,
    installInfo: String,
    installation: Object,
    userdata: Object,
    debugInfo: String,
    onSuccessCopy: Function
  },
  computed: {
    completeUrl() {
      return this.campaign.url + `?c=${this.campaign.id || this.campaign.generatedId}`
    },
    daypartingText() {
      const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

      return days.map((day, index) => {
        const schedule = this.filter.schedule[index] || [];
        
        const hasOrder = schedule.length && schedule.every((it, index, arr) => index !== arr.length - 1 ? it + 1 === arr[index + 1] : true);
        let text;

        if (hasOrder) {
          text = schedule.length && schedule.length >= 2 ? `From ${schedule[0]} - To ${schedule[schedule.length - 1]}` : schedule[0];
        } else {
          text = schedule.join(', ')
        }
    
        return `<div class="ml-3">${day}: ${text}</div>`
      }).join('')
    }
  }
}
</script>
