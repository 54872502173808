<template>
  <div class="d-flex justify-content-between border-top pt-10">
    <div class="mr-2">
      <button
        class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
        data-wizard-type="action-prev"
      >
        Previous
      </button>
    </div>
    <div>
      <button
        v-on:click="submit"
        class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
        data-wizard-type="action-submit"
      >
        Save
      </button>
      <button
        class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
        data-wizard-type="action-next"
        @click="submit($event, true)"
      >
        Next Step
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    submit: Function
  }
}
</script>
